import React, { Component, useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Container from 'components/Container';
import Headline from 'components/Headline';
import IconArrowDown from 'components/Svg/IconArrowDown';
import scrollToElement from 'scroll-to-element';
import vhCheck from 'vh-check';
import Redactor from 'components/Redactor';
import { bgGradientBlueOverlay50 } from '../Gradients';

const HeroSectionStyled = styled('section')(
  {
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    minHeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    zIndex: '0',
    height: 'calc(100vh - 74px)',

    // --vh-offset is from a plugin to take the mobile browser address bar into account
    '@supports(height: calc(100vh - var(--vh-offset, 0px)))': {
      height: 'calc(100vh - var(--vh-offset, 0px) - 74px)',
    },

    [media('lg')]: {
      height: 'calc(100vh - 140px)',

      '@supports(height: calc(100vh - var(--vh-offset, 0px)))': {
        height: 'calc(100vh - var(--vh-offset, 0px) - 140px)',
      },
    },
  },
  props => ({
    backgroundImage: props.backgroundImage,
  }),
);

const HeroContent = styled('div')({
  maxWidth: 1000,
  textAlign: 'center',
  color: 'white',
  padding: '100px 15px 0',
  margin: '0 auto',

  [media('xs', 'sm')]: {
    padding: '0 35px',
  },
  [media('lg')]: {
    width: '500px',
  },
});

const ButtonScrollDown = styled('button')({
  position: 'absolute',
  bottom: 50,
  outline: 'none',
  cursor: 'pointer',
  left: '50%',
  transform: 'translateX(-50%)',
});

export default class HomeHero extends Component {
  componentDidMount() {
    vhCheck();
  }

  render() {
    const {
      headline,
      backgroundImage,
      quote,
      awards,
    } = this?.props?.homeHero[0];
    const bgImage = backgroundImage[0]
      ? `url(' ${backgroundImage[0].hero} ')`
      : 'none';

    return (
      <HeroSectionStyled
        id="section-1"
        backgroundImage={bgImage}
        className={bgGradientBlueOverlay50}
      >
        <Container>
          <HeroContent>
            {headline && <Headline tag="h1">{headline}</Headline>}
          </HeroContent>
        </Container>
        <ButtonScrollDown
          onClick={() => {
            scrollToElement('#section-2', {
              align: 'top',
              duration: 300,
              offset: -50,
            });
          }}
        >
          <IconArrowDown />
        </ButtonScrollDown>
        <AwardSlider awards={awards} />
        {quote?.content && <Quote quote={quote} />}
      </HeroSectionStyled>
    );
  }
}

const AwardStyled = styled('img')({
  position: 'absolute',
  top: 10,
  right: 10,
  width: 140,

  [media('md')]: {
    top: 50,
    right: 50,
    width: 200,
  },
});

const AwardSlider = ({ awards }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const slideInterval = useRef(null);
  const ind = useRef(0);

  const nextSlide = () => {
    ind.current += 1;
    if (ind.current >= awards.length) ind.current = 0;
    setSlideIndex(ind.current);
  };

  useEffect(() => {
    slideInterval.current = setInterval(nextSlide, 3000);

    return () => {
      clearInterval(slideInterval.current);
    };
  }, []);

  return awards.map((file, index) => (
    <AwardStyled
      key={`award-${file}`}
      alt="Auszeichnung"
      src={`${file.url}`}
      style={{
        opacity: slideIndex === index ? 1 : 0,
        transition: 'opacity 200ms ease-out',
        transitionDelay: slideIndex === index ? '300ms' : '0ms',
      }}
    />
  ));
};

const QuoteStyled = styled('div')({
  position: 'absolute',
  color: 'white',
  maxWidth: '100%',
  padding: '60px 15px',
  textAlign: 'center',
  bottom: 10,

  p: {
    fontSize: 14,
    lineHeight: 1.3,
  },

  [media('md')]: {
    padding: '60px 60px',
    bottom: 0,
    right: 0,
    maxWidth: 500,
    textAlign: 'right',

    p: {
      fontSize: 22,
      lineHeight: 1.4,
    },
  },

  [media('lg')]: {
    textAlign: 'right',
    maxWidth: 800,
  },
});

const Quote = ({ quote }) => (
  <QuoteStyled>
    <Redactor content={quote.content} />
  </QuoteStyled>
);

export const query = graphql`
  fragment HomeHeroQuery on Craft_HomeHeroHero {
    headline
    awards {
      url(transform: transformAward)
    }
    quote {
      content
    }
    backgroundImage {
      url
      hero: url(transform: transformHero)
    }
  }
`;
